<template>
  <div>
    <!-- <Sidebar /> -->
    <div class="ps-2 mt-7 pt-7">
      <!-- <UserName /> -->
      <HeaderPage />
    </div>
    
    <div style="margin-bottom: 5em">
      <!-- <HeaderNavigasi  /> -->
      <div class="px-3 py-3">
        <table class="table table-stripe table-bordered border-primary">
          <tbody>
            <tr>
              <th>{{ $t("message.invoice-number") }}</th>
              <td>{{ orderDetail.invoice_no }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.order-number") }}</th>
              <td>{{ orderDetail.order_no }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.label-order-date") }}</th>
              <td>{{ orderDetail.ordered_date }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.label-delivered") }}</th>
              <td>{{ orderDetail.delivery_datetime }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.label-payment-method") }}</th>
              <td>{{ orderDetail.payment_method }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.address-name") }}</th>
              <td>{{ delivDestination.address_name }}</td>
            </tr>
            <tr>
              <th>{{ $t("message.label-address") }}</th>
              <td>
                {{ delivDestination.delivery_destination }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("message.label-store_name") }}</th>
              <td>
                {{ orderDetail.store_name }}
              </td>
            </tr>
            <tr v-if="orderDetail.is_locker != 0 && orderDetail.locker_key !== null || undefined">
              <th>ロッカーキー</th>
              <td
                @click.prevent="openLocker(orderDetail.id, orderDetail.locker_key)"
                style="font-weight: bold;color: #ff0090"
              >
                ロッカーキーを表示する
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-container>
        <hr />
        <div class="text-body" style="margin-top:10px">
          {{ $t("message.order-detail") }}
        </div>
        <div style="">
          <div v-for="(item, j) in orderDetail.details" :key="j" class="mb-4">
            <v-card
              class="d-flex justify-start"
              style="padding: 0px; margin: 0px;"
              flat
              tile
            >
              <v-avatar class="mr-5" size="100" tile>
                <v-img :src="item.image_url"></v-img>
              </v-avatar>
              <v-card class="pb-3" flat tile>
                <div class="">
                  <span
                    class="text-capitalize"
                    style="font-weight: bold; font-size: 14px"
                  >
                    {{ item.item_name }}
                  </span>
                </div>
                <div class="d-flex flex-row ">
                  <div>
                    <span
                      class="text-price text_color"
                      style="font-size: 18px;"
                    >
                      ¥
                      {{
                        priceBeforeComa(item.price_display_peritem)
                      }} </span
                    >x
                    <span>
                      {{ item.qty }}
                    </span>
                    <span class="ps-4">
                      ¥ {{ priceBeforeComa(item.price_display) }}
                    </span>
                    <br>
                    <span style="font-size:14px; color:#aaaaaa;">
                      {{ item.tax_type_display }}
                    </span>
                  </div>
                </div>
              </v-card>
            </v-card>
          </div>

          <div style="">
            <v-row class="py-6">
              <v-col cols="10">
                <v-row>
                  <v-col class="text-end pb-0" cols="9" v-if="orderDetail.subtotal > 0">
                    {{ $t("message.label-subtotal") }} :
                  </v-col>
                  <v-col cols="3" class="pb-0 text-end px-0" v-if="orderDetail.subtotal > 0">
                    <span>
                    ¥ {{ priceBeforeComa(orderDetail.subtotal) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_exclude_taxable_value_8 > 0"
                  >
                    {{ $t("message.label-tax_exclude-8%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_exclude_taxable_value_8 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span
                      

                    >
                    ¥ {{ priceBeforeComa(orderDetail.subtotal_exclude_taxable_value_8) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_consumption_tax_8 > 0"
                  >
                    {{ $t("message.label-consumption-tax-8%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_consumption_tax_8 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span
                      
                    >
                    ¥ {{ priceBeforeComa(orderDetail.subtotal_consumption_tax_8) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_exclude_taxable_value_10 > 0"
                  >
                    {{ $t("message.label-tax-exclude-10%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_exclude_taxable_value_10 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_exclude_taxable_value_10) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_consumption_tax_10 > 0"
                  >
                    {{ $t("message.label-consumption-tax-10%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_consumption_tax_10 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                    ¥ {{ priceBeforeComa(orderDetail.subtotal_consumption_tax_10) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_include_taxable_value_8 > 0"
                  >
                    {{ $t("message.label-tax-include-8%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_include_taxable_value_8 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_include_taxable_value_8) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_consumption_taxable_8 > 0"
                  >
                    {{ $t("message.label-consumption-taxable_8") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_consumption_taxable_8 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_consumption_taxable_8) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_include_taxable_value_10 > 0"
                  >
                    {{ $t("message.label-tax-include-10%") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_include_taxable_value_10 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_include_taxable_value_10) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_consumption_taxable_10 > 0"
                  >
                    {{ $t("message.label-consumption-taxable_10") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_consumption_taxable_10 > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_consumption_taxable_10) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    v-if="orderDetail.subtotal_non_taxable_value > 0"
                  >
                    {{ $t("message.label-non_taxable_value") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="orderDetail.subtotal_non_taxable_value > 0"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                      ¥ {{ priceBeforeComa(orderDetail.subtotal_non_taxable_value) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                    
                  >
                    {{ $t("message.label-delivery-fee") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    
                    class="text-end pb-0 px-0"
                  >
                    <span>
                    ¥ {{ priceBeforeComa(orderDetail.delivery_fee_display) }}
                    </span>
                  </v-col>
                  <v-col
                    class="text-end pb-0"
                    cols="9"
                  >
                    {{ $t("message.label-total") }} :
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-end pb-0 px-0"
                  >
                    <span>
                    ¥ {{ priceBeforeComa(orderDetail.total_amount_w_delivery_fee_per_store) }}
                    <!-- ¥ {{ priceBeforeComa(orderDetail.total_order_price) }} -->
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div style="backgroundColor: #E8EAF6; height: 15px; margin-top: 10px"></div>
          </div>
        </div>
      </v-container>

      <div style="margin-bottom: 8rem">
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
    <div>
      <Sidebar />
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/developmentv2/Sidebar.vue'
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
// import Sidebar from "../components/Sidebar.vue";
import Barcode from "vue-barcode";
import { mdiArrowLeft } from "@mdi/js";
import moneyFormat from "../helpers/moneyFormat";
import priceBeforeComa from '../helpers/priceBeforeComa'
// import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import Cashier from "../components/Cashier.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import User from "../components/User.vue";

export default {
  name: "OrderDetail",
  components: {
    Sidebar,
    barcode: Barcode,
    Cashier,
    UserName: User,
    CategoryFooter,
    // HeaderNavigasi,
    HeaderPage
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
    };
  },
  computed: {
    orderDetail() {
      return this.$store.state.order_module.orderDetail;
    },
    delivDestination() {
      return this.$store.state.order_module.delivDestination;
    },
  },
  created() {
    this.dispatchOrderDetail();
  },
  methods: {
    openLocker(id, lockerKey) {
      this.$router.push(`/order-history/${id}/${lockerKey}`);
    },
    formatPostalcode(postalcode) {
      if (postalcode != null) {
        let newPostcode = postalcode.slice(0, 3) + "-" + postalcode.slice(3);
        return newPostcode;
      } else {
        return;
      }
    },
    dispatchOrderDetail() {
      let id = this.$router.history.current.params.id;
      this.$store.dispatch("order_module/fetchOrderDetail", id);
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    priceBeforeComa(money) {
      return priceBeforeComa(String(money))
    }
  },
};
</script>
<style scoped>

.disabled {
  display: none;
}
.tax-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 50px;
}
.barcode .vue-barcode-element {
  width: 380px !important;
  height: 100px !important;
  justify-content: center !important;
  align-content: center !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
svg g text {
  font-weight: bold;
}
.row-header {
  background-color: white;
  height: 36px;
  width: 100%;
  margin-top: -15px;
  font-size: 24px;
  font-weight: 500;
}
.text-body {
  /* font-size: 18px;
  line-height: 24px;
  margin-left: 28px;
  font-weight: 400; */
}
table {
  min-width: 95%;
  margin: auto;
  /* border: 1px solid #707070; */
}
td {
  min-width: 40%;
  flex: 1;
  word-wrap: break-word;
}
tr {
  display: flex;
  flex-wrap: wrap;
}
tbody tr:nth-child(even) {
  background-color: #eeeeee;
}
tr + tr {
  /* border-top: 2px solid #707070; */
  border-color: #707070;

  padding: 0px !important;
}
tr th {
  /* background-color: #e0e0e0; */
  width: 33%;
  text-align: left;
  padding: 5px;
  /* text-overflow: w; */
}
tr td {
  padding: 5px !important;
}
</style>
